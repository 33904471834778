import React from "react"
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import { Link } from "gatsby"
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
const sciteam_partners = [
  { name: "City College of New York (CCNY)", link: "https://www.ccny.cuny.edu/" },
  { name: "Cooperative Institute for Research in the Atmosphere (CIRA)", link: "https://www.cira.colostate.edu/" },
  { name: "Goddard Space Flight Center (GSFC)", link: "https://www.nasa.gov/goddard" },
  { name: "National Oceanic and Atmospheric Administration (NOAA)", link: "https://www.noaa.gov/" },
  { name: "Stony Brook University (SBU)", link: "https://www.stonybrook.edu/" },
  { name: "University of California Los Angeles (UCLA)", link: "https://www.ucla.edu/" },
  { name: "University of Utah (UTAH)", link: "https://www.utah.edu/" },

]

const sciteam_partners_render = sciteam_partners.map((member) =>
  <span><a href={member.link} target={"_blank"}>{member.name}</a><br /></span>
);

const MissionPartnersPage = () => {
  return (
    <Layout pageTitle="INCUS Partners">
      <h1>INCUS Partners</h1>
          <h4>Primary Partners</h4>
            <CardGroup>
                <Card>
                  <a href="https://www.colostate.edu/" target="_blank">

                    <StaticImage
                      alt="Colorado State University logo"
                      src="../../images/csu_logo_long.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectFit="contain"
                      height={120}
                      className="card-img-top"
                    /></a>
                    <Card.Body>
                      <ul>
                      <li>PI Institution</li>
                      <li>Science team management</li>
                      <li>Algorithm development</li>
                      <li>Modeling and observational data analysis</li>
                      <li>Science Data Processing Center</li>
                      <li>Student collaboration</li>
                      </ul>
                    </Card.Body>
                </Card>
            <Card>
                    <a href="https://www.jpl.nasa.gov" target='_blank'>
                      <StaticImage
                        alt="JPL logo"
                        src="../../images/jpl_logo_meatball.png"
                        layout="constrained"
                        placeholder="blurred"
                        
                        height={120}
                        objectFit="contain"

                        className="card-img-top"

                      /></a>
                  <Card.Body>
                    <ul>
                    <li>Mission management</li>
                    <li>Radar and radiometer design, build and operations – based on RainCube and TEMPEST-D heritage</li>
                    <li>Algorithm development</li>
                    <li>Modeling and observational data analysis</li>
                    <li>System engineering</li>
                    <li>Mission design and navigation</li>
                    <li>Safety and mission assurance</li>
                    <li>Industry partner management and collaboration</li>
                    <li>Business management</li>
                    </ul>
                     </Card.Body>
                     </Card>

            </CardGroup>
            <h4>Satellite Design and Building</h4>
            <Col lg={8}>
            <CardGroup>
                <Card>
                  <a href="https://www.bluecanyontech.com/" target="_blank">
                    <StaticImage
                      alt="Blue Canyon Technologies logo"
                      src="../../images/bct_logo.png"
                      height={130}
                      className="card-img-top"
                      objectFit="contain"

                    /></a>
                    <Card.Body>
                      <ul>
                      <li>Design and manufacture the 3 satellite buses</li>
                      </ul>
                    </Card.Body>
                </Card>
            <Card>

                    <a href="https://www.tendeg.com" target='_blank'>
                      <StaticImage
                        alt="Tendeg Logo"
                        src="../../images/tendeg_logo.png"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"
                        height={130}
                        className="card-img-top"
                        objectFit="contain"
                        style={{height:130}}
                        transformOptions={{fit:"contain"}}

                      /></a>
                  <Card.Body>
                    <ul>
                    <li>Design and manufacture the deployable radar antennae</li>
                    </ul>
                     </Card.Body>
                     </Card>
            </CardGroup>
            </Col>
            <h4>Science Team Institutions</h4>
            <p>All Science Team Institutions assist in:
            </p>
            <ul>
            <li>Algorithm development</li>
            <li>Model and observational data analysis</li>
            <li>Field campaign planning, execution and analysis</li>

            </ul>
            <CardGroup>
            <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.ccny.cuny.edu/" target='_blank'>
                      <StaticImage
                        alt="City College of New York Logo"
                        src="../../images/ccny_logo.png"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>
            <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.nasa.gov/goddard" target='_blank'>
                      <StaticImage
                        alt="Goddard Space Flight Center Logo"
                        src="../../images/goddard_logo.jpg"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>
                     <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.noaa.gov/" target='_blank'>
                      <StaticImage
                        alt="National Oceanic and Atmospheric Administration Logo"
                        src="../../images/NOAA_logo.svg"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>
                     <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.stonybrook.edu/" target='_blank'>
                      <StaticImage
                        alt="Stony Brook University Logo"
                        src="../../images/stonybrook_logo.png"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>
                     <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.ucla.edu/" target='_blank'>
                      <StaticImage
                        alt="UCLA Logo"
                        src="../../images/ucla_logo.svg"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>
                     <Card style={{ width: '100%', alignItems: 'center' }}>
                  <div style={{ width: '90%', position: 'relative', alignItems: 'center', margin: 'auto' }}>

                    <a href="https://www.utah.edu/" target='_blank'>
                      <StaticImage
                        alt="University of Utah Logo"
                        src="../../images/utah_logo.png"
                        layout="constrained"
                        placeholder="blurred"
                        objectPosition="0 0"

                      /></a>
                  </div>
                     </Card>

                     </CardGroup>

    </Layout>

  )
}

export default MissionPartnersPage

